import React, {useState } from "react"
import styled from "styled-components"
import { colors } from "./Layout/colors"

const Form = styled.form`
  background: ${colors.text.white};
  box-shadow: 0px 25px 55px rgba(126, 126, 126, 0.15);
  border-radius: 16px;
  padding: 72px 32px;

  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 72px 96px;
  }

  @media (min-width: 1024px) {
    max-width: 600px;
    width: 98%;
    box-shadow: none;
  }
`

const Label = styled.label`
  font-family: Raleway;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${colors.text.title};
  margin-bottom: 18px;
  margin-top: 32px;
  display: block;

  &:first-child {
    margin-top: 0;
  }
`

const Input = styled.input`
  background: ${colors.bg.lightB};
  border: 1px solid ${colors.bg.base20};
  color: ${colors.bg.base80};
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  outline: none;

  &::placeholder {
    color: ${colors.text.field};
  }

  &:focus-visible {
    border: 1px solid ${colors.bg.base80};
    outline: none;
  }
`

const TextArea = styled.textarea`
  background: ${colors.bg.lightB};
  border: 1px solid ${colors.bg.base20};
  color: ${colors.bg.base80};
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  resize: vertical;
  &::placeholder {
    color: ${colors.text.field};
  }

  &:focus-visible {
    border: 1px solid ${colors.bg.baseD};
    outline: none;
  }
`

const SubmitBtn = styled.button`
  padding: 16px 24px;
  margin-top: 48px;
  display: block;
  margin-left: auto;
  font-size: 14px;
  line-height: 21px;

  @media (min-width: 1024px) {
    padding: 16px 48px;
  }
`

export default function ContactForm() {
  const INITIAL_STATE = {
    name: "",
    email: "",
    message: "",
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "updateFieldValue":
        return { ...state, [action.field]: action.value }

      default:
        return INITIAL_STATE
    }
  }

  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE)

  const updateFieldValue = field => event => {
    dispatch({
      type: "updateFieldValue",
      field,
      value: event.target.value,
    })
  }

  const [formState, setFormState] = useState('init');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target));
    setFormState('submitting');

    await fetch(`https://api.${window.location.host}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    e.target.reset();
    dispatch({ type: 'reset' });
    setFormState('submitted');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="contactName">Full Name</Label>
      <Input
        type="text"
        id="contactName"
        name="name"
        value={state.name}
        onChange={updateFieldValue("name")}
        placeholder="Enter your Full Name"
      />
      <Label htmlFor="contactEmail">Email Address</Label>
      <Input
        type="text"
        id="contactEmail"
        name="email"
        value={state.email}
        onChange={updateFieldValue("email")}
        placeholder="Enter your email address here"
      />
      <Label htmlFor="contactMessage">Message</Label>
      <TextArea
        rows={10}
        id="contactMessage"
        name="message"
        value={state.message}
        onChange={updateFieldValue("message")}
        placeholder="Write your messages in here"
      />
      { formState === 'submitted' ? 
        <p style={{ color: '#000'}}>Message received! Somebody from our office will be in touch.</p> : 
        <SubmitBtn disabled={formState === 'submitting' ? true : undefined}>{formState === 'submitting' ? 'Sending message...' : 'Send Message'}</SubmitBtn>
      }
    </Form>
  )
}
