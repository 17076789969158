import React from "react"
import styled from "styled-components"
import { colors } from "./Layout/colors"
import address from "../images/contact/address.svg"
import phone from "../images/contact/phone.svg"
import mail from "../images/contact/mail.svg"
import ContactForm from "./ContactForm"

const FormContainer = styled.div`
  padding: 32px 32px 48px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 33%;
    border-radius: 8px;
    background-color: ${colors.bg.baseD};
    z-index: -1;
  }

  p {
    color: ${colors.text.white};
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    margin: 72px 32px 48px;
    padding: 48px;
    gap: 32px;
    &::before {
      bottom: 0;
    }
  }

  @media (min-width: 1280px) {
    padding: 40px 40px 40px 104px;
    max-width: 1280px;
    margin: 72px auto 48px;
  }
`

const Tagline = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

const InfoContainer = styled.div`
  margin-bottom: 56px;
  @media (min-width: 1024px) {
    margin-top: 144px;
    margin-bottom: 0;
  }
`

const Address = styled.p`
  padding-left: 34px;
  position: relative;
  line-height: 150%;
  &::before {
    content: "";
    width: 18px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(${address}) no-repeat center;
  }
`

const Phone = styled.p`
  padding-left: 34px;
  position: relative;
  line-height: 150%;
  &::before {
    content: "";
    width: 18px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(${phone}) no-repeat center;
  }
`

const Mail = styled.p`
  padding-left: 34px;
  position: relative;
  line-height: 150%;
  &::before {
    content: "";
    width: 18px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(${mail}) no-repeat center;
  }
`

export default function ContactFormContainer() {
  return (
    <FormContainer>
      <div>
        <Tagline>contact us</Tagline>
        <h3 style={{ color: "white", margin: "32px 0" }}>Lets talk</h3>
        <InfoContainer>
          <h5 style={{ color: "white", marginBottom: "24px" }}>
            Contact Info:
          </h5>
          <Address>31 E Mack Bayou Dr, Santa Rosa Beach, FL, 32459</Address>
          <Phone>850-660-3616</Phone>
          <Mail>SantarosasmilesFD@gmail.com</Mail>
        </InfoContainer>
      </div>
      <ContactForm />
    </FormContainer>
  )
}
