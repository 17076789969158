import React from "react"
import styled from "styled-components"
import { colors } from "./Layout/colors"
import HeroBg from "../images/mobile-hero-bg.svg"
import HeroBgBig from "../images/mobile-hero-bg-big.svg"
import dotPattern from "../images/dot-pattern.svg"

const Contact = styled.section`
  position: relative;
  padding-bottom: 32px;

  @media (min-width: 1024px) {
    &::after {
      content: "";
      position: absolute;
      left: 48px;
      bottom: -36px;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
      z-index: 999;
    }
  }
`

const MobileBg = styled.div`
  padding: 112px 32px 48px;
  background-image: url(${HeroBg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  @media (min-width: 580px) {
    background-image: url(${HeroBgBig});
  }

  @media (min-width: 1024px) {
    background: none;
    padding: 172px 32px 0;
  }
`

const Title = styled.h4`
  color: ${colors.text.white};

  @media (min-width: 1024px) {
    color: ${colors.text.body};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: center;
    text-transform: uppercase;
  }
`

const Heading = styled.h4`
  margin: 24px 32px;
  @media (min-width: 1024px) {
    font-size: 48px;
    text-align: center;
  }
`

const Text = styled.p`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    line-height: 150%;
    text-align: center;
  }
`

export default function ContactHero() {
  return (
    <Contact>
      <MobileBg>
        <Title>Contact Us</Title>
      </MobileBg>
      <Heading>Let’s talk about smiles</Heading>
      <Text>We are here to help and answer any questions you might have.</Text>
    </Contact>
  )
}
