import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import PricingSection from "../components/PricingSection"
import Testimonials from "../components/Testimonials"
import ContactHero from "../components/ContactHero"
import ContactFormContainer from "../components/ContactFormContainer"

export default function AboutUs() {
  return (
    <Layout darkText>
      <Seo title="Contact Us" />
      <ContactHero />
      <ContactFormContainer />
      <PricingSection />
      <Testimonials />
    </Layout>
  )
}
